import React, { useEffect, useRef, useState } from "react";
import { badWords } from "./badwordlist";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { AcceptStatus, PartyMessage } from "../partyComponents/PartyTypes";
import { setMessageHistory } from "../../../store/messages";
import { joinRequestAccept, joinRequestReject, inviteAccept, inviteReject } from "../partyComponents/RestCalls";
import { PlayerType } from "../../../global";

export enum PartyCallStatus {
    NOT_CONNECTED,
    CONNECTED,
    DISCONNECTED
  }

export type msg = {
    msg: string,
    from: string,
    timestamp: string,
    playerId: string,
    avatar: string,
    responded?: boolean,
    partyMessage?: PartyMessage,
}

type chatBubbleProps = {
    index: number,
    className: string,
    msg: msg,
    timeClassName: string,
    lastMessageSameUser: boolean,
    isLastMessage: boolean,
    isFirstMessage: boolean,
    iFramePostMessage: Function
}

var Filter = require('bad-words'),
filter = new Filter();
badWords.forEach(word => {
    filter.addWords(word)
})

const isEmoji = (str:string) => {
    const regex = /[\p{Emoji}]/u;
    return regex.test(str);
}

const isValidMessage = (str:string) => {
    const regex = /[a-zA-Z]+/;
    return regex.test(str);
};

export const cleanMessage = (str: string) => {
    if (str != null && typeof str === 'string' && str.trim() !== '') {
        str = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        return isEmoji(str) || !isValidMessage(str) ? str : filter.clean(str);
    }
    return '';
};

export interface PlayerData {
    color: string;
    avatarUrl: string;
}
  
export const userColorMap: { [key: string]: PlayerData } = {};

export interface CSSPropertiesWithVars extends React.CSSProperties {
    '--gradient-color'?: string;
}

const ChatBubble: React.FC<chatBubbleProps> = ({index,className,msg,timeClassName,lastMessageSameUser, isLastMessage,isFirstMessage,iFramePostMessage}) => {
    
    const { users } = useAppSelector(state => state.usersOnline);
    const { images } = useAppSelector(state => state.messages)
    const { player, sessionId,id } = useAppSelector(state => state.appUser);
    const { messageHistory } = useAppSelector(state => state.messages)
    const [partyList,setPartyList] = useState<PlayerType[]>([])
    const usersOnlineRef = useRef(users)
    const playerIdReF = useRef(id)
    const dispatch = useAppDispatch();

    // Update refs
    useEffect(() => {
        usersOnlineRef.current = users
        playerIdReF.current = id
    }, [users,id])


    // Get the color assigned to the user
    function getColours(msg: string, avatar: string): string {
        const user = users.find(user => user.playerId === msg);
        return user ? user.colour : "red";
    }

      // Recieve Messages from the iFrame
      const handleIframeMessage = (event: MessageEvent) => {
        const data = event.data;
        if(data.type === "PARTY_MEMBERS"){
            setPartyList(data.members)
        }
        if(data.type === "END_CALL"){
            setPartyList([])
        }
      };
    
      // setup listener for messages
      useEffect(() => {
        window.addEventListener('message', handleIframeMessage);
    
        return () => {
          window.removeEventListener('message', handleIframeMessage);
        };
      }, []);
    
    //Check if the user status in the party list
    function checkIsOnline(playerId: string, players: PlayerType[]): boolean {
        console.log(players)
        console.log(playerId)
        if (players) {
            for (let player of players) {
                if (player.id === playerId ) {
                    switch (player.call_status) {
                        case "NOT_CONNECTED":
                            return false;
                        case "CONNECTED":
                            return true;
                        case "DISCONNECTED":
                            return false;
                        default:
                            return false;
                    }
                }
            }
        }
        return false;
    }

    const bubbleStyle = {
        backgroundColor: 'rgba(3, 5, 29, 0.25)',
        padding: '10px',
        borderRadius: '5px',
        marginTop: '10px',
        fontSize: 'large'
    };
    
    const nameStyle = {
        fontWeight: 'bold',
        fontSize: 'small',
        color:  getColours(msg.playerId, msg.avatar)
    };
    
    const timestampStyle = {
        color: 'lightgray',
        fontSize: 'small'
    };
    
    const getBubbleStyle = () => {
        let marginTop = 0;
        if (isFirstMessage) {
            marginTop = isLastMessage ? (index === 0 ? 70 : -45) : (index > 0 ? 20 : 0);
        } else if (isLastMessage) {
            marginTop = lastMessageSameUser ? (index > 0 ? -60 : 0) : (index > 0 ? 40 : 0);
        }
        return { marginTop };
    };
    
    const getImageContainerStyle = () => {
        let topValue;
    
        if (isLastMessage) {
            if(lastMessageSameUser){
                topValue = index > 0 ? - 70 : -10;
            }else {
                topValue = index > 0 ? - 50 : -10;
            }
            
        } else {
            topValue = index > 0 ? -70 : 0;
        }
    
        return {
            '--gradient-color': getColours(msg.playerId, msg.avatar),
            top: topValue
        };
    };

    const loadImage = (playerId: string): string => {
        let playerIndex = images.findIndex(existingUser => existingUser.playerId === playerId);
        
        if (playerIndex !== -1) {
            return images[playerIndex].imageObjecturl;
        } else {
            return "https://models.readyplayer.me/66c870b093f9fe518a518f81.png";
        }
    };

    const formatUnityMessage = (msg: msg) => {
        if(msg.msg.includes("has requested to join")){
            return <div style={bubbleStyle}>    
                    {msg.msg}
                 {
                    msg.responded ? null : <div> <span style={{color: 'green'}}>Would you like to accept?
                    <button onClick={() => handleInviteRequest(msg.partyMessage, AcceptStatus.ACCEPT_REQUEST)}>Yes</button>
                    <button onClick={() => handleInviteRequest(msg.partyMessage, AcceptStatus.REJECT_REQUEST)}>No</button>
                    </span></div>
                 }
                </div>
        } else if (msg.msg.includes("You have been invited to")){
            return <div style={bubbleStyle}>    
                    {msg.msg}
                 {
                    msg.responded ? null : <div> <span style={{color: 'green'}}>Would you like to accept?
                    <button onClick={() => handleInviteRequest(msg.partyMessage, AcceptStatus.ACCEPT_INVITE)}>Yes</button>
                    <button onClick={() => handleInviteRequest(msg.partyMessage, AcceptStatus.REJECT_INVITE)}>No</button>
                    </span></div>
                 }
                </div>
        } else {
            return<div style={bubbleStyle}>    
                    {msg.msg}
                    </div>
        }
    }
    
    const handleInviteRequest = (partyMessageIncoming: PartyMessage | undefined, type: AcceptStatus) => {
        
        if(partyMessageIncoming == undefined){
            console.log("Party info is undefined")
            return
        } else {
            console.log(partyMessageIncoming)
            let partyMessage: PartyMessage = {
                fromPlayerId: partyMessageIncoming.toPlayerId,
                toPlayerId: partyMessageIncoming.fromPlayerId,
                partyInfo: partyMessageIncoming.partyInfo,
                fromPlayerToPeerId: "",
                textMessage: "",
                sessionId: sessionId
            }
            switch(type){
                case AcceptStatus.ACCEPT_REQUEST:
                    console.log(partyMessage)
                    joinRequestAccept(partyMessage).then(res => {
                        console.log(res.data)
                    })
                    break;
                case AcceptStatus.REJECT_REQUEST:
                    joinRequestReject(partyMessage).then(res => {
                        console.log(res.data)
                    })
                    break;
                case AcceptStatus.ACCEPT_INVITE:
                    inviteAccept(partyMessage).then(res => {
                        console.log(res.data)
                    })
                    break;
                case AcceptStatus.REJECT_INVITE:
                    inviteReject(partyMessage).then(res => {
                        console.log(res.data)
                    })
                    break;
                default:
                    console.log("no Status Passed into handle invite Request")
                    break;
            }
            updateMessageHistory(index, true)
     
     
        }        
    }

    const updateMessageHistory = (index: number, responded: boolean) => {
        let updatedState = messageHistory.map((message: any, i: number) =>
            i === index ? { ...message, responded } : message
        )
        dispatch(setMessageHistory(updatedState));
    };

    return (
        <div>
            <div className="outer-image-container">
            {isLastMessage && msg.playerId != "54321" && (
                            <div className="image-container" style={getImageContainerStyle()}>
                                <div className="image-inner-container">
                                    <img alt="" className={`chat-bubble-image  ${msg.avatar === "https://avatars.nl-ams-1.linodeobjects.com/soledad1.png" ? "fbx" : ""}`} src={msg.avatar === "https://avatars.nl-ams-1.linodeobjects.com/soledad1.png" ? "https://avatars.nl-ams-1.linodeobjects.com/soledad1.png" : loadImage(msg.playerId)} title={msg.from}/>
                                </div>
                                <div>
                                    <span className={`dot-text-chat ${checkIsOnline(msg.playerId, partyList) ? "green-text-chat" : "red-text-chat"}`}>
                                        {'\u25CF'}
                                    </span>
                                </div>
                            </div>
                        )}

            </div>
             
            <div className="chat-bubble-container" style={{marginBottom: 0}}>
            {lastMessageSameUser && !isLastMessage ? (
                <li className="chat-bubble">
                   {
                                    msg.playerId === "54321" ? formatUnityMessage(msg) : <div style={bubbleStyle}>
                                                                                                {msg.msg}
                                                                                            </div>
                    }
                </li>
                    ) : (
                    <>
                       

                        {(isFirstMessage || isLastMessage) && (
                            <li className="chat-bubble" style={getBubbleStyle()}>
                                {isFirstMessage && (
                                    <>
                                        <span style={{ color: getColours(msg.playerId, msg.avatar) }}>{'\u25CF'} </span>
                                        <span style={nameStyle}>
                                            {msg.from.length > 0 ? msg.from : "Guest"}
                                        </span>
                                        <span style={timestampStyle}> {msg.timestamp}<br /></span>
                                    </>
                                )}
                                  {
                                    msg.playerId === "54321" ? formatUnityMessage(msg) : <div style={bubbleStyle}>
                                                                                                {msg.msg}
                                                                                            </div>
                                }
                            </li>
                        )}
                    </>
                )}
            </div>
        </div>

    );
}

export default ChatBubble;