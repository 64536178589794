import React, { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { CSSPropertiesWithVars } from "./ChatBubble";
import axios from "axios";
import { setImages } from "../../../store/messages";


export const UsersOnline: React.FC = () => {

    const { users } = useAppSelector(state => state.usersOnline);
    const { player, update } = useAppSelector(state => state.appUser);
    const { images } = useAppSelector(state => state.messages);
    const baseLeftPercentage = 380 / 1000 * 100;
    const incrementPercentage = 30 / 1000 * 100;
    const playerRef = useRef(player);
    const imagesRef = useRef(images);
    const usersRef = useRef(users)
    const dispatch = useAppDispatch();

    useEffect(() => {
        playerRef.current = player;
        imagesRef.current = images;
        usersRef.current = users;
    }, [player, images, users])

    // listen for update firing so that new images can be collected (update is fired by readPlayerMe and The updateAvatar ws message)
    useEffect(() => {
        usersRef.current.forEach(user => {
            console.log(user)
            if (user.playerId !== "54321") {
                if(user.avatarUrl === "cba6e927-9de4-4e03-a190-df9262359f14"){
                    loadImage("https://avatars.nl-ams-1.linodeobjects.com/soledad1.png", user.playerId);
                } else if(user.avatarUrl){
                    loadImage(user.avatarUrl.replace(".glb", ".png"), user.playerId);
                }
            }
        })
    }, [update])

    // load images on Mounting of component
    useEffect(() => {
        usersRef.current.forEach(user => {
            if (user.playerId !== "54321") {
                if(user.avatarUrl === "cba6e927-9de4-4e03-a190-df9262359f14"){
                    loadImage("https://avatars.nl-ams-1.linodeobjects.com/soledad1.png", user.playerId);
                } else if(user.avatarUrl){
                    loadImage(user.avatarUrl.replace(".glb", ".png"), user.playerId);
                }
            }
        })
    }, [])

    // fetch the image from ready player me with no cache header to force update of png
    const loadImage = async (url: string, playerId: string) => {

        try {
            const response = await axios.get(url, {
                responseType: 'blob',
                headers: {
                    'Cache-Control': 'no-cache'
                }
            });

            const imageUrl = URL.createObjectURL(response.data);

            const imgElements = document.getElementsByClassName(playerId) as HTMLCollectionOf<HTMLImageElement>;

            for (let i = 0; i < imgElements.length; i++) {
                const imgElement = imgElements[i];
                imgElement.src = imageUrl;
            }
            updateImages(playerId, imageUrl)

        } catch (error) {
            console.error('Error loading image:', error);
        }
    };

    // update or store the imageUrl and playerId in redux to use in the chat bubble component
    const updateImages = (playerId: string, objectUrl: string) => {
        let currentImages = [...imagesRef.current];

        const existingUserIndex = currentImages.findIndex(existingUser => existingUser.playerId === playerId);

        if (existingUserIndex === -1) {
            currentImages.push({ playerId, imageObjecturl: objectUrl });
        } else {
            currentImages[existingUserIndex] = { playerId, imageObjecturl: objectUrl };
        }

        dispatch(setImages(currentImages));
    };
    
    return(
        <div>
            {usersRef.current.map((user, index) => (
                index < 3 ? 
                    <div className="image-container-online" style={
                        {
                            '--gradient-color': user.colour,
                            position: 'absolute', 
                            borderRadius: '50%',  
                            left: `${baseLeftPercentage + (index * incrementPercentage) + 40}%`,
                            zIndex: users.length + index,
                            top: '40px',
                        } as CSSPropertiesWithVars
                    }>
                        <div className="users-online-inner-image-container">
                        <img 
                            className={`user-online-image ${user.playerId} ${user.avatarUrl === "cba6e927-9de4-4e03-a190-df9262359f14" ?  "fbx": ""}`}
                            key={index}
                            alt=""
                            src={user.playerId === "54321" ? user.avatarUrl : ""}
                            style={{ 
                                borderRadius: '50%', 
                            }}
                        /> 
                        </div>
                    </div>
                    :
                    <div className="image-container-online" style={
                        {
                            '--gradient-color': "rgb(100,234,255)",
                            borderRadius: '50%', 
                            position: 'absolute', 
                            padding: '11px',
                            background: 'linear-gradient(349deg, rgba(104,40,162,1) 30%, rgba(119,205,221,1) 85%)',
                            color: 'white',  
                            left: `${baseLeftPercentage + (3 * incrementPercentage) + 40}%`,
                            zIndex: usersRef.current.length + index,
                            top: '42px',
                            height: 20,
                            width: 20
                        } as CSSPropertiesWithVars
                    }>
                     +{(usersRef.current.length) - 3}
                       
                    </div>
                ))}
        </div>
    )
}