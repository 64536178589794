import { createSlice} from "@reduxjs/toolkit";

interface SceneState {
    name: string,
    moduleId: string,
    index:string | undefined
    sceneTiles: SceneTiles[]
}


export interface SceneTiles {
    name: string,
    moduleId: string,
    image: string,
    index:string | undefined
}

const initialState: SceneState = {
    name: "",
    moduleId: "",
    index: "",
    sceneTiles: [
        {
            name: "Soledad Studio",
            moduleId: "4ec94d65-4127-45da-9032-54d0a3f255c8",
            image: "",
            index: process.env.REACT_APP_UNITY_BUILD_FILE_INDEX_GENNIUS_SOLEDAD_STUDIO
        },
        {
            name: "The Hub",
            moduleId: "804d21fc-0d53-4376-ae5a-30b751791986",
            image: "",
            index: process.env.REACT_APP_UNITY_BUILD_FILE_INDEX_GENNIUS_HUB
        }
    ]
}

export const sceneStateSlice = createSlice({
    name:"scenes",
    initialState,
    reducers: {
        setSceneState: (state, action) => {
            state = action.payload
        },
        setName: (state, action) => {
            state.name = action.payload
        },
        setModuleId: (state, action) => {
            state.moduleId = action.payload
        },
        setIndex: (state, action) => {
            state.index = action.payload
        }
    }
});

export const { setName, setModuleId,setSceneState, setIndex } = sceneStateSlice.actions;

export default sceneStateSlice.reducer;